import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);
export default function Navigation(props) {
  const classes = useStyles();
  return (
    <Header
      brand=""
      color="dark"
      leftLinks={
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              href="/games"
              className={classes.navLink}
              color="transparent"
            >
              Games
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/news"
              className={classes.navLink}
              color="transparent"
            >
              News
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/roadmap"
              className={classes.navLink}
              color="transparent"
            >
              Road Map
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/about"
              className={classes.navLink}
              color="transparent"
            >
              About Us
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/contact"
              className={classes.navLink}
              color="transparent"
            >
              Contact
            </Button>
          </ListItem>
        </List>
      }
    />
  );
}
