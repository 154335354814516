import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import Carousel from "react-slick";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Navigation from "components/Navigation/Navigation.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import Image from "react-image-resizer";

import imgDirgeStory from "assets/img/game/1theStory.png";
import imgDirgeGameplay from "assets/img/game/2gameplay.png";
import imgDirgeFeatures from "assets/img/game/3features.png";

import imgDirgeImage1 from "assets/img/game/img1.png";
import imgDirgeImage2 from "assets/img/game/img2.png";
import imgDirgeImage3 from "assets/img/game/img3.png";
import imgDirgeImage4 from "assets/img/game/img4.png";
import imgDirgeImage5 from "assets/img/game/img5.png";
import imgDirgeImage6 from "assets/img/game/img6.png";
import imgDirgeImage7 from "assets/img/game/img7.png";
import imgDirgeImage8 from "assets/img/game/img8.png";
import imgDirgeImage9 from "assets/img/game/img9.png";
import imgDirgeImage0 from "assets/img/game/img0.png";

import styles from "assets/jss/material-kit-react/views/components.js";
import "assets/css/nonnobis.css";

const useStyles = makeStyles(styles);

export default function GamesPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="NonNobis Games LLC"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={"https://i.imgur.com/RseLyvK.jpeg"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>NonNobis Games</h1>
                <h3 className={classes.subtitle}>Indie Devs making game we love to play!</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Navigation />
        <h1 style={{ textAlign: "center" }}>Games</h1>
        <GridContainer justify="center">
          <GridItem xs={4} sm={4} className={classes.marginLeft}>
            <Image src={imgDirgeStory} width={500} height={100} />
            <Image src={imgDirgeImage1} width={680} height={320} />
            The early 20th century was rife with death and darkness on a global
            scale. The destruction and chaos of the Great War was quickly
            followed by a deep depression, only to be capped off with the
            horrors of the 2nd World War. Darkness on a much smaller scale such
            as brutal murders and abuse can on their own result in hauntings of
            tormented spirits and dark entities, the horrors of the turn of the
            century have thus cast the recovering world into a black shadow.
            <br />
            <br />
            Across the world supernatural horrors that have long been suppressed
            since their days of myth and dominance are bleeding into the gaps
            left in the chaos of the wars. All corners of the globe have become
            infested by the restless spirits of betrayed soldiers, widowed
            wives, victims of criminal activity, and poor souls who have fallen
            victim to similar entities long lost to folklore.
            <br />
            <br />
            Governments, organizations, and vigilantes who are aware of the
            battle against the dark entities creeping out of the gaps in the
            world move to keep the truth from the general populace. This is
            where our investigators draw their ranks. Government agents, men of
            faith, researchers, and vagabond hunters join forces to tackle the
            most dangerous of these dark entities in anguished locations across
            the globe. In Dirge you step into the post war era to explore the
            haunted mansion of Hillview. Your goal is to investigate the entity
            lurking there, discover its weaknesses, and defeat it... if you can.
            <Image src={imgDirgeImage2} width={680} height={320} />
            <Image src={imgDirgeGameplay} width={500} height={100} />
            <h3>Investigators</h3>
            Dirge uses procedural code to randomize the item locations and types
            across the levels to make sure you never solve the same hunt twice.
            Keys to rooms will choose from hundreds of artist placed locations
            to spawn, as will clues, equipment, or haunted traps. Additionally
            we have produced extremely modular level assets which allows us to
            swiftly create and nest dozens of different floor plans and layouts
            for the same map sets, so no game in the mansion will ever be the
            same. This will help maintain a sense of the unknown even if it is
            your hundredth game in a level, as rooms and floor plans will have
            shifted along with the items you need to find and collect. Add this
            variation to the different survival strategies you have to employ to
            avoid being brutally murdered by the three different entities we
            plan to launch with, and Dirge should continue to be a thrilling
            game even years after its release.
            <br />
            <br />
            <Image src={imgDirgeImage3} width={680} height={320} />
            While that is the design philosophy of our systems and sounds fun,
            the real question you have is "Yes but what do you do, how do you
            play?"
            <br />
            <br />
            <Image src={imgDirgeImage4} width={680} height={320} />
            <Image src={imgDirgeImage5} width={680} height={320} />
            Well the short answer is, you explore the house and participate in a
            deadly scavenger hunt. The investigators enter the manor of Hillview
            with at minimum their light source and a revolver, and must find
            other equipment and weapons to help them succeed.
            <h3>Monsters</h3>
            <br />
            <br />
            <Image src={imgDirgeImage6} width={680} height={320} />
            The dark entities are an entirely different case. Born into
            darkness, or perhaps corrupted into it, they move through the manor
            like they own the place. That is because they do. Many playable
            entities interact directly with the world, driving lesser spirits
            and creatures before them in terror, or perhaps gazing out at the
            investigators from frost rimmed mirrors as they stalk them from
            another realm. The entities of the house sometimes know what the
            investigators are looking for, seeing them highlighted in the world
            so they can plan their ambushes, other more powerful spirits don't
            bother conceiving what the living are doing in their realm, and
            simply allow their power and implacable presence win the day.
            Monster players move around the level much like a living
            investigator, however nearly all of them have ways of fast travel
            around the map. Some phase out of physical form so they can float at
            higher speeds and pass through doors, others climb in and out of
            mirrors using them as portals into 'the other side', and others
            potentially teleport. These abilities, as well as their lethal
            capabilities are generally governed by a resource, their rage. The
            encroachment of the living into their domain slowly builds their
            rage, and the actions of the investigators and the entity can either
            slow or speed up the accumulation of rage in the monster as its
            loathing grows. The player controlling the creature can then spend
            the rage on various abilities to help them find, haunt, and
            ultimately murder all of the interlopers.
            <br />
            <br />
            The question simply becomes who will succeed first. Will the
            investigators finish their scavenger hunt, equipping them to destroy
            or banish the monster. Perhaps they have to burn the bones of the
            spirit, and finding the remains and the means to set it alight is
            their goal. Or perhaps finding the Bloody Maid's true mirror via
            clues in the world so they can shatter it is the only way to clear
            the house of her evil. Regardless, their time limit is how well the
            monster can play his deadly game of cat and mouse, hide and seek,
            and all the while both sides have very human intelligence working
            together or against one another.
            <br />
            <br />
            <Image src={imgDirgeImage7} width={680} height={320} />
            <br />
            <br />
            <Image src={imgDirgeFeatures} width={500} height={100} />
            <ul>
              <li>1v4 Multiplayer Survival Horror.</li>
              <li>5 Player Coop Survival Horror</li>
              <li>Three unique monsters to survive/murder with</li>
              <li>Ten unique weapons to fight the creatures of the house.</li>
              <li>
                Collect equipment, items, and clues to defeat the Entities while
                they hunt you down.
              </li>
            </ul>
            <Image src={imgDirgeImage8} width={680} height={320} />
            <Image src={imgDirgeImage9} width={680} height={320} />
            <Image src={imgDirgeImage0} width={680} height={320} />
          </GridItem>
          <GridItem xs={4} sm={4}>
            <GridContainer>
              <GridItem>
                <iframe
                  id="iframe_steam"
                  title="Check out Dirge on Steam!"
                  src="https://store.steampowered.com/widget/1374580/"
                  frameBorder="0"
                  width="600"
                  height="200"
                ></iframe>
              </GridItem>
              <GridItem>
                <iframe
                  id="iframe_yt1"
                  title="Update Video"
                  width="600"
                  height="315"
                  src="https://www.youtube.com/embed/lXf1gr8OY1U"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </GridItem>
              <GridItem>
                <iframe
                  id="iframe_yt2"
                  title="Official Trailer"
                  width="600"
                  height="315"
                  src="https://www.youtube.com/embed/jDRN9wmIoAs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </GridItem>
              <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
                <Carousel sm={4}>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_f8b4822631bc390f9826366000e4a89902dd9533.1920x1080.jpg"
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Vince stares at Hillview Manor..</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_ccab29902d8c2c0b5560817a96b1289732fea25d.1920x1080.jpg"
                      alt="Second slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Diana finds a key!</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_4fa6fb9c7a81449fc14be25507aa451fa67c8acc.1920x1080.jpg"
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Avery investigates for items while being hunted by a Wraith.</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_6e205b91e978ddb9c46ae2020923f78bf5b8a061.1920x1080.jpg"
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Camila makes her way into the Wakefield subway.</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_b8bae96f49af0642299e03d80118777ba80497ea.1920x1080.jpg"
                      alt="Second slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>James takes on a ghoul with the trusty M1911.</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_beceff16c292d1a8ed3c7bb470d7a57c885c6f52.1920x1080.jpg"
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Quetico forest lurks with a graveyard leading to a crypt.</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_b6bb699f5281a6296e6c83d3e92e0e3e91b942ca.1920x1080.jpg"
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Play as the Wraith and stalk your friends.</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_737b06498dbaf72f3d1afd8c21225ee68c4defd0.1920x1080.jpg"
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>Vince shoots his shot, and misses.</h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1374580/ss_bf82872ef916c565507be6d9823d555cf34bfbb1.1920x1080.jpg"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>The crew readies up for the next investigation.</h4>
                    </div>
                  </div>
                </Carousel>
              </GridItem>
              <GridItem />
            </GridContainer>
          </GridItem>
        </GridContainer>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
}
