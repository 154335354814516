import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Navigation from "components/Navigation/Navigation.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import Info from "components/Typography/Info.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import "assets/css/nonnobis.css";

const useStyles = makeStyles(styles);

export default function NewsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="NonNobis Games LLC"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 800,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={"https://i.imgur.com/RseLyvK.jpeg"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>NonNobis Games</h1>
                <h3 className={classes.subtitle}>Indie Devs making game we love to play!</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Navigation />
        <h1 className="centerContent">Contact</h1>
        <GridContainer>
          <GridItem className="centerContent">
            <Info className="centerContent">admin@non-nobis-games.com</Info>
          </GridItem>
        </GridContainer>
      </div>
      <Footer />
    </div>
  );
}
