import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
//import Carousel from "react-slick";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Navigation from "components/Navigation/Navigation.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import "assets/css/nonnobis.css";

const useStyles = makeStyles(styles);

export default function RoadMapPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="NonNobis Games LLC"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 500,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={"https://i.imgur.com/RseLyvK.jpeg"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>NonNobis Games</h1>
                <h3 className={classes.subtitle}>Indie Devs making game we love to play!</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Navigation />
        <h1 style={{ textAlign: "center" }}>
          <img
            src="https://i.imgur.com/DAdpU8L.png"
            alt="Road Map"
            width={900}
            className={
              classes.imgRaised +
              " " +
              classes.imgRounded +
              " " +
              classes.imgFluid
            }
          />
          <br />
          Road Map
        </h1>
        <GridContainer justify="center">
          <GridItem lg={8} justify="center">
            <div style={{ textAlign: "center" }}>
              <br />
              We're working hard at Non Nobis Games to extend and improve our
              first released product, Dirge. Thanks to our fantastic
              Early-Access community and teams of streamers, we've discovered
              and squashed hundreds of bugs in the months since opening
              Early-Access. We incorporated so much since then such as the
              underlying code for aesthetic choices, character stats, and other
              community-driven adjustments to the first game after initial
              performance optimizations, bug corrections, balancing tweaks, and
              quality of life enhancements. Going ahead, we want to keep this
              open and community-driven development going, therefore in the
              spirit of this studio objective, we're putting out a roadmap of
              our presently in production significant features, complete with
              specifics, so you can anticipate them and provide feedback.
              <br />
              <br />
              <h2>WENDIGO</h2>
              <img
                src="https://i.imgur.com/PZ7blFm.png"
                alt="Wendigo"
                width={900}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <br />
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3136199798769571091">
                  Completed with v0.2 on 2022-03-15
                </a>
              </h3>
              <del>
                Several ideas for improving our other creature, The Revenant,
                were made during gameplay testing of our monsters. A jump attack
                or gap closer, the ability to push or shove players about to
                perhaps separate them from their group, and a more complicated
                way of defeating them besides brute force damage were all
                suggested. While coding these suggestions and evaluating the
                original Revenant's ability 'kit,' it became clear that the
                skills were essentially identical to the Wendigo's legend. We
                already had Wendigo assets on hand, so we decided to apply what
                we'd learned from our previous physical creature to a new
                monster. From this work the community received the Wendigo
                release that came out in version 0.2.
                <br />
                <br />
                We'll be watching broadcasts, stats, and community feedback in
                the future to help dial in the general feeling of balance of
                this new monster. With the help of our fantastic community,
                we're certain that any major issues will be spotted and
                addressed in the future, ensuring that the Wendigo is as full
                and polished as our Wraith.
              </del>
              <br />
              <br />
              <h2>FOREST MAP</h2>
              <img
                src="https://i.imgur.com/Ygdvx81.png"
                alt="Forest"
                width={900}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <br />
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/6007253767851920437">
                  Completed with v0.3 on 2022-07-01
                </a>
              </h3>
              <del>
                Our players have been getting to grips with the mysteries of
                Hillview Manor, as we work on a second map for the game in the
                background. In an ideal world, release maps that could serve as
                the home of each monster. The Wraith was considered when
                designing the Manor, and the upcoming wintery forest was chosen
                as the following map to reflect the Wendigo. It features a large
                snowy and woodland region on the outside, but it also has
                cabins, graveyards, and an underground tunnel system where a
                beast like the Wendigo may hunt. In addition, there are secrets
                and hints to the aims and machinations of some underground
                occultist group alluded at in Hillview, just as there are in
                Hillview.
                <br />
                <br />
                Taking the gamers out of the traditional mansion and placing
                them in a chilly forest environment would create a whole new
                gaming experience. Players can enjoy the clean mountain air as
                they're being stalked by supernatural creatures.
              </del>
              <br />
              <br />
              <h2>REVENANT REWORK</h2>
              <img
                src="https://i.imgur.com/8IcPQmc.png"
                alt="Revenant"
                width={900}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/5850769927599199695">
                  Completed with v0.4.1 on 2022-12-26
                </a>
              </h3>
              <br />
              <del>
                As we highlighted the Wendigo earlier, it very much leaves the
                old Revenant without an identity. The Wendigo is similar to the
                Revenant, but it is more powerful and has had more time to
                evolve. However, the lanky creeper that we have all loved in the
                early months will not be forgotten. The Revenant will be
                redesigned as our first Hybrid monster, with the Wraith being an
                Ethereal horror and the Wendigo being a Physical bruiser. We'll
                explain the new direction of our old friend when he enters
                active development. Exact information on his new and enhanced
                mechanics are still under wraps while we focus on the Forest map
                and continuing bug squashing.
              </del>
              <br />
              <br />
              <h2>ADDITIONAL CHARACTERS</h2>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3406429723554039847">
                  Diana Added with v0.3.2 on 2022-08-22
                </a>
              </h3>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3531414035636632507">
                  Kiyoshi Added with v0.4 on 2022-12-01
                </a>
              </h3>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3649643127874563349">
                  Camila Added with v0.4.6 on 2023-03-16
                </a>
              </h3>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3687937038727971472">
                  Bonnie Added with v0.5.3 on 2023-08-13
                </a>
              </h3>
              <del>
                Dirge now has four investigators and three monsters in our cast.
                The human investigators may appear to be getting left behind
                with the release of the Wendigo and the anticipated revamp of
                the Revenant. Fear not, as we have plans to enhance the number
                of playable investigators in the future to enable for more
                active participants and a wider range of options. Our first
                female investigator is the first of these. We don't want to give
                anything away about her upcoming release while she's still in
                development. But don't worry, she's on her way! Following her
                release, we plan to expand the number of characters available to
                players.
              </del>
              <br />
              <br />
              <h2>UI IMPROVEMENTS</h2>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3389544395162797588">
                  Completed with v0.3.4 on 2022-09-24
                </a>
              </h3>
              <del>
                <br />
                Things like crouched indication icons, expanded battery inputs,
                bad ammunition readouts, and other rapid workarounds have
                overtaken the initial GUI design we had in place at the start of
                Early Access as we've introduced more and more quality of life
                enhancements. With additional icons, information, and comments
                from our community, we want to totally overhaul our UI. This
                will involve modifications to tooltips, game feedback icons, the
                HUD, and other areas. This is a larger effort, but we believe it
                will significantly improve our game's appearance and readability
                for players.
              </del>
              <br />
              <br />
              <h2>PLAYER PROGRESSION</h2>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/6148001803572636945">
                  Completed with v0.3.5 on 2022-10-22
                </a>
              </h3>
              <del>
                <br />
                Many members of our community have inquired about our intentions
                for player progression. Some people have specifically requested
                it. We had no intentions for a player progression system in
                Dirge at first, but the community was quite adamant about the
                idea, and it was pointed out that advancement like this
                frequently extends the life of a game for a player by giving
                them something to strive for. We agree, and we're working on a
                tiered solution that we can incorporate into the game and scale
                up if it's a success. The initial system might just be a player
                level that you can raise by playing games and performing actions
                in the game.
                <br />
                <br />
                In the long run, tying cosmetics to a system like this is an
                obvious objective, and we'll investigate the potential.
              </del>
              <br />
              <br />
              <h2>BUT WHEN</h2>
              <h3>
                <a href="https://store.steampowered.com/news/app/1374580/view/3881604511643938386">
                  Released into v1.0 on 2024-02-01
                </a>
              </h3>
              <del>
                <br />
                "But Non Nobis!" I hear you say, "You have not said when these
                features are planned for release!" The reason is simple: we
                don't know for sure, and we don't want to commit to time frames
                that we will most likely miss. As an independent dev team with
                only 2 two active in engine devs, how quickly we do tasks is
                mostly dependent on how well our day jobs are going. We could
                possibly estimate to a higher degree of precision when we could
                deliver these new features if we were able to work on it full
                time or employ extra hands, but that is not the reality of
                development at Non Nobis. The Wendigo, like the Forest map and
                our next female character, has been in development behind the
                scenes for months. Other things are in the planning stages or
                are in the process of being developed
                <br />
                <br />
                This roadmap is more of a priority list for us than a timeline,
                and it is not in the sequence in which things will be released.
                Instead, these are things that we believe we can commit to
                providing to our community prior to a complete v1.0 release.
                That said, it's not etched in stone that we won't listen to
                community comments or let your enthusiasm for the game influence
                our objectives. We're just convinced that this list was compiled
                with the input of our community in mind, and that it will pique
                the interest of the vast majority of our player base.
                <br />
                <br />
              </del>
              Join our discord, as always, and participate in the conversation
              and fun. If you have any thoughts or recommendations, please let
              us know through our suggestion channels. We appreciate any and all
              input that our wonderful community has to give.
              <br />
              <br />
              Thank you all.
              <br />
              <br />
              <h3>NON NOBIS GAMES</h3>
              <img
                a="https://i.imgur.com/rqrdN1r.png"
                alt="NonNobis Games Logo"
              />
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
}
