import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Navigation from "components/Navigation/Navigation.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

// import imageteam from "assets/img/faces/partialTeam.jpg";
import imageteam2 from "assets/img/faces/teamVR.jpg";
import imagemp from "assets/img/faces/mp.jpg";
import imageaz from "assets/img/faces/az.jpg";
import imagedp from "assets/img/faces/dp.jpg";
import imagepr from "assets/img/faces/pr.jpg";
import imagemm from "assets/img/faces/mm.jpg";
import imageeb from "assets/img/faces/eb.jpg";
import imagegg from "assets/img/faces/gg.jpg";
import imagelr from "assets/img/faces/lr.png";
import imagenh from "assets/img/faces/nh.jpg";
import imagenc from "assets/img/faces/nc.jpg";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function AboutPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        brand="NonNobis Games LLC"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 800,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={"https://i.imgur.com/RseLyvK.jpeg"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>NonNobis Games</h1>
                <h3 className={classes.subtitle}>Indie Devs making game we love to play!</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Navigation />
        <div id="images">
          <h1 style={{ textAlign: "center" }}>About Us</h1>
          <GridContainer justify="center">
            <GridItem xs={80} sm={2}>
              <h2 style={{ textAlign: "center" }}>The Company</h2>
              NonNobis Games, based out of Florida, develops and publishes games
              that we ourselves love to play and hope you do as well. We take
              enormous pride in creating quality content that show cases the
              story we want to immerse our players in. We see video games as
              more than just the mindless entertainment our parents always said
              and see them as a piece of art, culture and storytelling for the
              21st century. We are a small shop of just a few friends
              collaborating on a shared vision. We believe that the process is
              as important as the product and that`s why we prefer to release
              our content when it is ready instead of an arbitrary deadline.
            </GridItem>
            <GridItem xs={80} sm={2}>
              <img
                src={imageteam2}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={80} sm={2}>
              <h2 style={{ textAlign: "center" }}>The Current Team</h2>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" align="center">
            <GridItem sm={2} justify="center" className={classes.marginLeft}>
              <img
                src={imagepr}
                alt="..."
                width={200}
                height={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>Phil R</h4>
              <h5>Lead Developer</h5>
              With a lifelong passion for video games, my journey began with the
              original Nintendo and Gameboy, eventually evolving into a love for
              PC gaming and computers. I discovered programming through the
              Dungeons and Dragons game NeverWinter Nights and its Aurora
              Toolset, becoming captivated by the world of software development.
              <br />
              <br />
              Over the years, I have gained proficiency in numerous programming
              languages, including C++, Perl, Python, C#, Java, Bash, and React.
              After being introduced to the Unreal Engine, I found enthusiasm
              for combining my programming and gaming passions. With a strong
              foundation in software development and a dedication to exploring
              the gaming industry, I eagerly look forward to exciting new
              opportunities in this dynamic field.
              <br />
              <br />
            </GridItem>
            <GridItem sm={2} justify="center">
              <img
                src={imagemp}
                alt="..."
                width={200}
                height={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>
                Manuel P{" "}
                <a
                  href="https://www.linkedin.com/in/manuel-pineiro-aa26b691"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={classes.socialIcons + " fab fa-linkedin"} />
                </a>
              </h4>
              <h5>Lead Artist</h5>
              I try to avoid horror games and movies because I don't like the
              fear... except I have a morbid need to know what happens. This has
              led me to play a lot of horror games and complete almost none of
              them. I migrate to multiplayer horror games because they offer the
              thrill in smaller terrifying bits, and there is nothing worse than
              knowing the thing chasing me has a human intelligence behind its
              hunt. I have been studying and learning the various aspects of
              game art and design for four years or so, because I think gaming
              is the next frontier of entertainment and my favorite hobby. It
              would be outstanding if I could commit my day job hours to working
              on this game and others going forward.
              <br />
              <br />
            </GridItem>
            <GridItem sm={2} justify="center">
              <img
                src={imagenc}
                alt="..."
                width={200}
                height={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>
                Nando C{" "}
                <a
                  href="https://www.linkedin.com/in/nando-cordeiro-078986215"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={classes.socialIcons + " fab fa-linkedin"} />
                </a>
              </h4>
              <h5>Sound Designer</h5>
              Sound Design is one of the most crucial elements for any true
              horror experience. I got fascinated with digital audio when I
              started using Logic Pro to help me compose music. I began to see
              that the sounds anyone could create were absolutely endless and
              when used in the context of media such as video games, sound can
              create a whole world of immersion. I love the meditative
              experience of analyzing the sound of the world around me, as well
              as both the creative and technical traits of sound design, it fits
              my personality so well. Working on Dirge has been super fun and a
              great learning experience. I love hearing about the players`
              reactions towards the sound world I have made for Dirge.
              <br />
              <br />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" align="center">
            <GridItem sm={2} justify="center">
              <img
                src={imageeb}
                alt="..."
                width={200}
                height={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>
                Eddie B{" "}
                <a
                  href="https://www.twitch.tv/chrono2055"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={classes.socialIcons + " fab fa-twitch"} />
                </a>
              </h4>
              <h5>Developer</h5>
              Hey guys! While I'm a family man with two awesome little boys, I'm
              still a gamer too! I've always had a deep seated passion for
              gaming in general, but I've always had a soft spot for horror
              games ever since Silent Hill back on the PS1. I grew up on the NES
              and SNES, and I still play those consoles from time to time. Ever
              since I was a kid, I wanted to get into creating games, but was
              never given a path forward. This led me to try to get into
              standard programming, but it never took off. Recently I've been
              given the chance to jump back into my childhood dream and work on
              Dirge in Unreal Engine 4 with the other amazing developers, and I
              can't wait to see what comes next for us.
              <br />
              <br />
            </GridItem>
            <GridItem sm={2} justify="center">
              <img
                src={imagelr}
                alt="..."
                width={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>
                Billy D{" "}
                <a
                  href="https://www.facebook.com/AcidMorphNZ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                </a>
              </h4>
              <h5>Lead Tester</h5>
              I was born in 1992 in New Zealand, and I currently reside in the
              Waikato region. I love science fiction, horror, and video games.
              Generally, it's best to combine all three! I'm the front man for
              AcidMorph, where I sing lead vocals . I have two sibling Maine
              Coon cats: Baba Yaga (Black Female cat) and Loki (Grey Male cat).
              <br />
              <br />
            </GridItem>
            <GridItem sm={2} justify="center" className={classes.marginLeft}>
              <img
                src={imagenh}
                alt="..."
                width={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>
                Nadine H{" "}
                <a
                  href="https://www.twitch.tv/MorgueMistress"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={classes.socialIcons + " fab fa-twitch"} />
                </a>
              </h4>
              <h5>Tester</h5>
              I go by Morgue and have spent the majority of my life enjoying
              games from the sidelines. I eventually had the good fortune to
              begin playing video games as an adult and have since been making
              up for lost time. I usually focus on horror and indie video games.
              <br />
              <br />
              It has become a passion to test games in order to make them
              better. In terms of understanding the level of effort required for
              each patch and update, Dirge has been a wonderfully rewarding
              learning experience. I also helped establish the community to
              provide feedback that improves Dirge. It has been a privilege to
              assist the NonNobis crew. I'm curious to see the ideas this team
              develops and the surprises I'll get to experience next.
              <br />
              <br />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={80} sm={2}>
              <h2 style={{ textAlign: "center" }}>Former Team Members</h2>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" align="center">
            <GridItem sm={2} justify="center" className={classes.marginLeft}>
              <img
                src={imageaz}
                alt="..."
                width={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>
                Andres Z{" "}
                <a
                  href="https://www.artstation.com/andres_zambrano"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={classes.socialIcons + " fab fa-artstation"} />
                </a>
              </h4>
              <h5>Artist</h5>
              I'm a 3D artist with a passion for video games, especially
              survival horror games. I started wanting to become an animator
              when I was a kid but then I discovered how to model 3D characters
              and environments through watching video tutorials online.
              <br />
              <br />
              I decided to go to school at The Art Institute and graduated with
              a Bachelor of Fine Arts in Game Art and Design. I have always
              wanted to create a game of my own as well as having my own game
              development studio; to this end I have decided to work on Dirge
              with the hope that it becomes the first in a long line of games to
              come.
              <br />
              <br />
            </GridItem>
            <GridItem sm={2} justify="center" className={classes.marginLeft}>
              <img
                src={imagegg}
                alt="..."
                width={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>Garret G</h4>
              <h5>Community Manager</h5>
              I have been playing games for as long as I remember, having been
              schooled repeatedly by my mother on the original NES. This habit
              continued for the entirety of my life and has led to a passion for
              the industry. I did eventually discover while I was not the most
              skilled in programming or art, I had a knack for spread the word
              and getting people to engage, which led me to community management
              and eventually marketing. I was also raised by some rather unique
              individuals, which has led to a love for the occult and
              supernatural, that actually ended up leading to me meeting Manny
              and Phil to advise them on some monsters and help keep everything
              on track with their lore. After seeing the immense passion that
              they had already put forward into what was quickly becoming a
              favorite game, I felt inspired to cast my lot and join the team to
              help get some real passion and spirit back into gaming.
              <br />
              <br />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" align="center">
            <GridItem sm={2} justify="center" className={classes.marginLeft}>
              <img
                src={imagedp}
                alt="..."
                width={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>Dominic P</h4>
              <h5>Developer</h5>
              I've been a horror fan for many years, always looking for the next
              terrifying game to keep me up at night. When given the chance to
              combine this with my hobby of designing games I took it
              immediately. I've since taught myself to use the Unreal Engine
              with the help of other coders and the wonderful community of
              developers on the internet. I would love to transform this hobby
              into a career that can support my family.
              <br />
              <br />
            </GridItem>
            <GridItem sm={2} justify="center" className={classes.marginLeft}>
              <img
                src={imagemm}
                alt="..."
                width={200}
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <h4>Matt M</h4>
              <h5>Developer</h5>
              I'm a Network Engineer by day, and avid hobbyist during my free
              time. I've been gaming for almost 30 years since my early days
              with an NES and moving on through the generations with new and
              improved devices. I hold a BS in Computer Science and enjoy the
              different challenges that coding a video game provides.
              <br />
              <br />
            </GridItem>
          </GridContainer>
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}
