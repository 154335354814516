import { Component } from 'react';

// This file is heavily based on:
// https://github.com/ReactTraining/react-router/blob/v3/modules/Redirect.js
export default class ExternalRedirect extends Component {
  render() {
    return (
      window.location.replace(this.props.link)
    );
  }
}