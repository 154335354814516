import React, { Component }  from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import ExternalRedirect from "components/ExternalRedirect.js";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import GamesPage from "views/Games/Games.js";
import RoadMapPage from "views/RoadMap/RoadMap.js";
import NewsPage from "views/News/News.js";
import AboutPage from "views/About/About.js";
import ContactPage from "views/Contact/Contact.js";

// important redirect links
const kickstarter = 'https://www.kickstarter.com/projects/dirgegame/dirge-an-a-symmetric-multiplayer-horror-game';
const steam = 'https://store.steampowered.com/app/1374580/Dirge';
const youtube = 'https://www.youtube.com/channel/UCuSSh6FsyOVEp5nSRfm98Dg';
const instagram = 'https://www.instagram.com/dirge.pc.game';
const imgur = 'https://imgur.com/user/NonNobisGames';
const twitter = 'https://twitter.com/NonNobisGames';
const facebook = 'https://facebook.com/NonNobis-Games-102477124859625';
const twitch = 'https://www.twitch.tv/directory/game/Dirge';
const discord = 'https://discord.com/invite/78hzA3zFy6';
const linktree = 'https://linktr.ee/NonNobisGames';
const paypal = 'https://www.paypal.com/paypalme/nonnobisgames';

ReactGA.initialize("UA-174905412-1");

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;

var hist = createBrowserHistory();

ReactPixel.init('241043694657122');
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

hist.listen(location => {
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
});

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/games" component={withTracker(GamesPage, { /* additional attributes */ })} />
      <Route path="/roadmap" component={withTracker(RoadMapPage, { /* additional attributes */ })} />
      <Route path="/news" component={withTracker(NewsPage, { /* additional attributes */ })} />
      <Route path="/about" component={withTracker(AboutPage, { /* additional attributes */ })} />
      <Route path="/contact" component={withTracker(ContactPage, { /* additional attributes */ })} />
      <Route path="/kickstarter" render={(props) => <ExternalRedirect link={kickstarter} {...props} />} />
      <Route path="/steam" render={(props) => <ExternalRedirect link={steam} {...props} />} />
      <Route path="/youtube" render={(props) => <ExternalRedirect link={youtube} {...props} />} />
      <Route path="/instagram" render={(props) => <ExternalRedirect link={instagram} {...props} />} />
      <Route path="/imgur" render={(props) => <ExternalRedirect link={imgur} {...props} />} />
      <Route path="/twitter" render={(props) => <ExternalRedirect link={twitter} {...props} />} />
      <Route path="/facebook" render={(props) => <ExternalRedirect link={facebook} {...props} />} />
      <Route path="/twitch" render={(props) => <ExternalRedirect link={twitch} {...props} />} />
      <Route path="/discord" render={(props) => <ExternalRedirect link={discord} {...props} />} />
      <Route path="/linktree" render={(props) => <ExternalRedirect link={linktree} {...props} />} />
      <Route path="/paypal" render={(props) => <ExternalRedirect link={paypal} {...props} />} />
      <Route path="/press" render={(props) => <ExternalRedirect link={paypal} {...props} />} />
      <Route path="/" component={withTracker(GamesPage, { /* additional attributes */ })} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
